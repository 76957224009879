body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 /*------------------------------------*\
    # Fonts
  \*------------------------------------*/
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap');
  /*------------------------------------*\
    # Global
  \*------------------------------------*/
  html {
      box-sizing: border-box;
      height: 100%;
      }
      *, *:before, *:after {
        box-sizing: inherit;
      }

    body {
      height: 100%;
      min-height: 100%;
      font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #575756;
    }

    h3 {
      font-style: normal;
      font-weight: bold;
    }