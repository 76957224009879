@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 /*------------------------------------*\
    # Fonts
  \*------------------------------------*/
  /*------------------------------------*\
    # Global
  \*------------------------------------*/
  html {
      box-sizing: border-box;
      height: 100%;
      }
      *, *:before, *:after {
        box-sizing: inherit;
      }

    body {
      height: 100%;
      min-height: 100%;
      font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #575756;
    }

    h3 {
      font-style: normal;
      font-weight: bold;
    }
.kw-details-reject:hover,
.kw-details-reject:focus {
  color: #C53741 !important;
  background-color: #fff;
  border-color: #C53741 !important;
}

.ant-modal-footer .ant-btn-primary {
    background-color: #2b476c;
    border-color: #2b476c;
    color: #ffffff;
    border-radius: 3px;
  }
.ant-form-item-label {
    text-align: left !important;
    width: 100%;
}

.ant-form-item-control {
    margin-top: -10px;
}
.button-success-leads-dashboard {
    background-color: #2b476c !important;
    border-color: #2b476c !important;
    color: #ffffff !important;
    border-radius: 3px !important;
  }
label.kw-onGoing-rejected.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background: #ff8a9b !important;
  border-color: #ff8a9b;
}

label.kw-onGoing-rejected.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #ff8a9b !important;
  border-color: #ff8a9b;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: unset !important;
}

.ant-radio-button-wrapper:first-child {
  border: 1px solid #43adf7 !important;
  border-left: 1px solid #43adf7 !important;
}

.ant-radio-button-wrapper:last-child {
  border: 1px solid #ff8a9b !important;
  border-left: 1px solid #ff8a9b !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background: #ff8a9b !important;
}

.ant-modal {
    width: 800px !important;
}
